import buildPatternBackground from "./buildPatternBackground";

/**
 * Builds a string that can be used as the CSS `background` property.
 *
 * Assumes valid values from Sitecore for background position and background size:
 *
 * Background position can be:
 * - `top`, `bottom`, `left`, `right`, `center`, `top left`, `top right`, `bottom left`, `bottom right`
 *
 * Background size can be:
 * - `contain`, `cover`
 * @param {*}
 * @returns String that can be used as a background layer
 */
const buildBackgroundString = ({ fields, isMobile }) => {
  const backgroundImageString = fields.backgroundImage?.value?.src
    ? `url('${fields.backgroundImage.value.src}') ${
        fields.backgroundPosition?.name || "top left"
      }${
        fields.backgroundSize?.name ? `/${fields.backgroundSize.name}` : ""
      } no-repeat`
    : "";

  const patternBackgroundString = buildPatternBackground({
    isMobile,
    patternBackgroundLeft: fields.patternBackgroundLeft,
    patternBackgroundRight: fields.patternBackgroundRight,
  });

  const finalBackgroundString = [patternBackgroundString, backgroundImageString]
    .filter((value) => value)
    .join(", ");

  return {
    backgroundString: finalBackgroundString,
    hasPatternBackground: !!patternBackgroundString,
  };
};

export default buildBackgroundString;

const patternBackgroundConfig = {
  // cochlear kids page

  // light purple with dots
  "cochlear-kids-pattern-1-desktop": "right -100px top -100px/400px",
  "cochlear-kids-pattern-1-mobile": "right -125px top -125px/250px",
  // yellow with dots
  "cochlear-kids-pattern-2-desktop": "-100px 150px/400px",
  "cochlear-kids-pattern-2-mobile": "-150px 150px/250px",
  // dark purple with curved lines
  "cochlear-kids-pattern-3-desktop": "right 0px top -200px/400px",
  "cochlear-kids-pattern-3-mobile": "right -125px top -100px/250px",
  // yellow with round shapes
  "cochlear-kids-pattern-4-desktop": "left -125px bottom -75px/400px",
  "cochlear-kids-pattern-4-mobile": "left -125px bottom -75px/250px",
  // light purple with straightish lines
  "cochlear-kids-pattern-5-desktop": "right -200px top 0px/400px",
  "cochlear-kids-pattern-5-mobile": "right -125px top -100px/250px",
  // yellow with dots shapes
  "cochlear-kids-pattern-6-desktop": "right -125px bottom -225px/400px",
  "cochlear-kids-pattern-6-mobile": "right -125px bottom -75px/250px",

  // professional system page
  "multi-circle-dots-desktop": "right -25px top 100px/500px",
  "multi-circle-dots-mobile": "top -150px center/150%",

  "multi-circle-stripes-desktop": "left -200px top -100px/800px",
  "multi-circle-stripes-mobile": "top -400px center/250%",

  "brand-wave-desktop": "top right/300px",
  "brand-wave-mobile": "top right/90%",

  "single-circle-dots-desktop": "left -100px bottom -300px/600px",
  "single-circle-dots-mobile": "left -200px bottom -300px/500px",

  // professional sound processor, implant pages
  "inverse-curve-bottom-desktop": "bottom right/450px",
  "inverse-curve-bottom-mobile": "bottom right/90%",

  // baha start
  "baha-start-pattern-7-desktop": "left -240px bottom -700px/800px",
  "baha-start-pattern-7-mobile": "left -370px bottom -530px/800px",

  "baha-start-pattern-8-desktop": "right -180px top -20px/400px",
  "baha-start-pattern-8-mobile": "right top / 0",

  "baha-start-pattern-9-desktop": "left -160px bottom 40px/400px",
  "baha-start-pattern-9-mobile": "left -220px bottom 0/400px",

  "baha-start-pattern-10-desktop": "right -400px bottom -120px/800px",
  "baha-start-pattern-10-mobile": "right -200px bottom -40px/400px",

  "baha-start-pattern-11-desktop": "", // use default top left
  "baha-start-pattern-11-mobile": "",

  "baha-start-pattern-12-desktop": "left -200px bottom -200px/500px",
  "baha-start-pattern-12-mobile": "left -100px bottom -100px/250px",
};

/**
 * Builds a string of one or more pattern backgrounds to use as a background style property
 * @param {*} param0
 */
const buildPatternBackground = ({
  isMobile,
  patternBackgroundLeft,
  patternBackgroundRight,
}) => {
  const backgroundImageParts = [];

  if (patternBackgroundLeft) {
    backgroundImageParts.push(
      `url('${
        (isMobile && patternBackgroundLeft.fields.imageMobile?.value?.src) ||
        patternBackgroundLeft.fields.image.value.src
      }') ${
        patternBackgroundConfig[
          `${patternBackgroundLeft.name}-${isMobile ? "mobile" : "desktop"}`
        ]
      } no-repeat`,
    );
  }

  if (patternBackgroundRight) {
    backgroundImageParts.push(
      `url('${
        (isMobile && patternBackgroundRight.fields.imageMobile?.value?.src) ||
        patternBackgroundRight.fields.image.value.src
      }') ${
        patternBackgroundConfig[
          `${patternBackgroundRight.name}-${isMobile ? "mobile" : "desktop"}`
        ]
      } no-repeat`,
    );
  }

  return backgroundImageParts.join(", ");
};

export default buildPatternBackground;

const MobileExternalBackground = ({ backgroundString, position }) => {
  // extra div because this gets placed inside a bootstrap .row that styles its immediate children
  return (
    <div>
      <div
        className={`mobile-external-background ${
          position === "before" ? "mb-4" : "mt-4"
        }`}
        style={{ background: backgroundString }}
      />
    </div>
  );
};

export default MobileExternalBackground;
